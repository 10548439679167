<template>
  <div class="container-fluid mt-3">
    <Form @submit="handleSubmit" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الأماكن الإعلانية' , path: '/places'}" current_page="إضافة مكان إعلاني"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="handleSubmit" :disabled="loading">حفظ</button>
        </div>
      </div>

      <placement-form :errors="errors" :place="place" :handleChange="handleOnChange"/>

    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { Form} from "vee-validate";
import {createPlace} from "@/services/ads";
import PlacementForm from "@/views/ads/componente/placeForm";

export default {
  name      : "Add_adsPlace",
  components: {
    PlacementForm,
    Breadcrumb,
    Form,
  },
  data()
  {
    return {
      loading  : false,
      place: {
        name: '',
        identifier: '',
        type: '',
        width: '',
        height: '',
        active: true

      }
    }
  },
  methods: {
    handleOnChange(key, val)
    {
      this.place = {
        ...this.place,
        [key]: val
      }
    },
    handleSubmit() {
      this.loading = true
      createPlace(this.place)
      .then(()=>{
        this.loading = false
        this.$router.push('/places')
      })
      .catch(()=>this.loading = false)
    }
  }
}
</script>

